import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
// import 'moment-timezone';
import './App.css';
import gpsIcon from "./img/gps.png";
import notAllowed from "./img/exclamation-mark.png";

export default class App extends Component {

  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    this.baseUrl = process.env.REACT_APP_API_URL;
    this.state = {
      userId: urlParams.get('user_id'),
      arenaHash: urlParams.get('id'),
      licensePlate: urlParams.get('vehicle_no'),
      passId: urlParams.get('pass_id')
    };
  }

  componentDidMount(){
    this.getArenaDetails();
  }

  manageBodyBackground(isShow){
    if(isShow){
      document.querySelector('#root').classList.add('dark');
    }else{
      document.querySelector('#root').classList.remove('dark');
    }
  }

  getArenaDetails(){
    let { arenaHash, licensePlate, passId, userId } = this.state;
    if(!arenaHash || !licensePlate || !passId || !userId){
      toast.error('Params missing in URL!');
      return;
    }
    fetch(`${this.baseUrl}/pay/arena/details?arena_hash=${arenaHash}`)
        .then(response => response.json())
        .then(resp => {
          if(resp.data && resp.status === "SUCCESS"){
            this.setState({ 
              parkingId: resp.data.parking_id,
              name: resp.data.name
            })
          }else{
            let errMsg = resp.error && resp.error.reason ? resp.error.reason : 'Something went wrong'; 
            toast.error(errMsg);
          }
        })
        .catch((error) => {
          toast.error('Something went wrong');
        });
  }

  handleSubmit(e){
    e.preventDefault();
    document.getElementById("pay-submit").disabled =  true;
    if(this.state.loading) return;
    const submitBtn = document.getElementById("pay-submit");
    this.manageBodyBackground(true);
    this.setState({loading : true});
    let { parkingId, licensePlate, passId, userId } = this.state;
    const request = {
      parking_id: parkingId,
      vehicle_no: licensePlate,
      pass_id: passId,
      user_id: userId
    }
    fetch(`${this.baseUrl}/parking/monthly/subscription/cancel`, {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(request)
     })
      .then(response => response.json())
      .then(resp => {
        if(resp.data && resp.status === "SUCCESS"){
          window.scrollTo(0,0);
          this.setState({booked: true, loading: false});
        }else{
          let errMsg = resp.error && resp.error.reason ? resp.error.reason : 'Something went wrong'; 
          this.manageBodyBackground(false);
          this.setState({loading : false});
          toast.error(errMsg, { autoClose: errMsg.length > 20 ? 10000 : 2000 });
          submitBtn.disabled = false;
        }
      })
      .catch((error) => {
        this.manageBodyBackground(false);
        this.setState({loading : false});
        toast.error('Something went wrong');
        submitBtn.disabled = false;
      });  
  }

  handleClose(){
    this.setState({booked: false, bookedID: null }, () => {
      this.manageBodyBackground(false);
    })
  }


  render() {
    return (
      <div style={{position: 'relative'}}>
        {this.state.isVehicleOnboarded === false &&  <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.90)'}}></div>}
        {this.state.loading &&  <div style={{position: 'absolute', width: '100%', height: '100%', zIndex: 10}}></div>}
        {this.state.loading && <div className="loader">Loading...</div>}
        <div style={{display: this.state.booked ? 'block' : 'none'}} className="success-message">
          <svg viewBox="0 0 76 76" className="success-message__icon icon-checkmark">
            <circle cx="38" cy="38" r="36"></circle>
            <path fill="none" stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.7,40.9l10.9,10.9l28.7-28.7" style={{strokeDashoffset: 0, strokeDasharray: 56.0029}}></path>
          </svg>
          <h3 className="success-message__title">Cancelled Successfully</h3>
          <p style={{position: 'relative', top: '28px', fontSize: '12px', color: '#888'}}>(You can close this tab)</p>
        </div>
        <form style={{pointerEvents: this.state.booked ? 'none' : 'auto'}} onSubmit={this.handleSubmit} action="#" method="POST">
          <input type="hidden" name="token" />
          <div style={{textAlign: 'center', paddingBottom: 20}}>
            <img style={{height: 85}} src="https://portal.parkquility.com/public/pq_logo.png" />
          </div>
          <div>
            <h2 style={{textAlign: 'center', fontSize: '22px'}}>Monthly Parking Pass</h2>
          </div>
          <div style={{textAlign: 'center', padding: '30px 0'}} className="location">
            <img style={{height: 35}} src={gpsIcon} /> 
            <span>{this.state.name}</span>
          </div>

          <ul className="pay-timings">
            <li>
              <span className="list-initial">License Plate No.</span>
              <span style={{textTransform: 'uppercase'}}>{this.state.licensePlate}</span>
            </li>
          </ul>
          
          <button style={{marginBottom: '60px'}} id="pay-submit" type="submit">Cancel Subscription</button>
          <div style={{clear: 'both'}} />
          
          <ToastContainer autoClose={2000} closeButton={true} style={{fontSize: 14}}/>
        </form>
      </div>      
    )
  }
}
